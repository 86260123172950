<template>
    <div role="main">
        <section class="hero">
            <div class="hero-body">
                <div class="container has-text-centered">
                    <h1 class="title xl mb-0 pb-2">Solutions</h1>
                    <div class="div">
                        <h3 class="title is-3">Text here</h3>
                        More text here<br>
                    </div>
                    <!-- <router-link :to="{name: 'Home'}">
                        <button class="button is-primary is-radiusless has-text-weight-bold is-block mt-4 is-inline-block primary-button">
                        Back to Home<i class="fas fa-arrow-right ml-2 primary-button-icon"></i>
                        </button>
                    </router-link> -->
                </div>
            </div>
        </section>
    </div>
</template>

<script>
export default {
    name: 'Solutions',
    data: () => {
        return {
            name: 'Solutions'
        };
    },
    metaInfo: {
        title: 'Solutions',
        template: null
    }
};
</script>

<style scoped></style>
